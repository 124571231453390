@import "./mixins.scss";

:root {
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  --body-color: #f4f4f4;
  --black: #04152d;
  --black2: #041226;
  --black3: #020c1b;
  --black-lighter: #1c4b91;
  --black-light: #173d77;
  --pink: #da2f68;
  --orange: #ff5733;
  --yellow: #f39c12;
  --green: #0e870e;
  --white: #fff;
  --green1: #51e38f;
  --text-gray: #a8a5af6e;
  --gradient: linear-gradient(98.37deg, #f89e00 0.99%, #da2f68 100%);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-display: swap;
  font-display: auto;
  font-display: block;
  font-display: fallback;
  font-display: optional;
}

ul {
  list-style: none;
  padding-left: unset !important;
}

li a {
  text-decoration: none;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: var(--body-color);
  font-family: 'Poppins', sans-serif;
}

.wrapper {
  width: 100%;
  position: relative;
}

.contentWrapper {
  height: 100%;
  margin-bottom: 55px;
  margin-left: unset;

  @include md {
    margin-left: 120px;
  }
}

.row {
  --bs-gutter-x: 1rem !important;
  margin-left: unset !important;
  margin-right: unset !important;
}

.form-label {
  margin-bottom: 0.5rem;
  color: #000;
  font-weight: 600;
}

.active {
  font-weight: 600;
  background: var(--black);

  svg {
    color: var(--white) !important;
  }

  span {
    color: var(--white) !important;
  }
}

p {
  margin-bottom: unset !important;
}

div.dataTables_wrapper div.dataTables_info,
div.dataTables_wrapper div.dataTables_paginate {
  padding-top: unset !important;
  padding-bottom: unset !important;
  font-weight: 400;
}

table.table thead th {
  border-top: none;
  font-weight: 600;
}

table.table-sm th,
table.table-sm td {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  font-weight: 400;
}

.react-loading-skeleton {
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block;
  background-color: #b9b6b6;
  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
  z-index: 1;
}

div.dataTables_wrapper div.dataTables_info,
div.dataTables_wrapper div.dataTables_paginate {
  padding-top: unset !important;
  padding-bottom: unset !important;
  font-weight: 400;
  text-align: left;
}

.col-12 {
  padding: 0.15rem !important;
}

.cardform {
  padding: unset !important;
  border-top: 3px solid red;
}

.btnDelete {
  border: none;
  background: red;
  color: #fff;
  padding: 2px 2px 1px 4px;
  border-radius: 5px;
}
.btnAssign{
    border: none;
    background: rgb(16, 10, 90);
    color: #fff;
    padding: 2px 2px 1px 4px;
    border-radius: 5px;
}
.btnDetail{
  border: none;
  background: #0ed9aa;
  color: #fff;
  padding: 2px 2px 1px 4px;
  border-radius: 5px;
}
.btnEdit {
  background: #17b49e;
  color: #fff !important;
  padding: 5px 5px 5px 9px;
  border-radius: 5px;
}

.btnsec {
  gap: 10px;
  display: flex;
  justify-content: center;
}

.form-heading {
  display: flex;
  padding: 10px 0px 0px 10px;

  svg {
    margin-top: 2px;
  }
}

hr {
  margin: 0.5rem 0 !important;
}

.btnstatus {
  border: none;
}

.btnsection {
  display: flex;
  gap: 10px;
}

.form-label {
  margin-bottom: 0.5rem;
  color: #000;
  font-weight: 500;
  margin-top: 0.5rem;
}

.marbottom {
  margin-bottom: 60px;
}

.modal-lg {
  max-width: 1300px;
}

.modal-header {
  padding: 5px 10px 5px 10px;
}

.productdetails {
  th {
    padding: 0.5rem;
    padding-top: unset;
    padding-bottom: unset;
  }
}

.detailsdiv {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  .pname {
    font-weight: 500 !important;
  }
}